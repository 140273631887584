
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import { setABCUser, getABCUser } from '@/utils/cookies'

@Component({
  name: 'zhchbank',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
    @Provide() public loginParam: string = '';

    public async created () {
      this.loginParam = getCurrentQuery('loginParam')
      if (!getCurrentQuery('redirect')) { // 配置不带redirect前端自己手动重定向加
        this.setUrl()
      }
      this.doAuth()
    }

    @Emit()
    public setUrl () {
      let { source = '11', loginParam = '' } = this.$route.query
      const str = loginParam
      location.replace(`zhchbank?redirect=https%3A%2F%2Fm.benlai.com&source=11&loginParam=${str}`)
    }

    @Emit()
    public async doAuth () {
      const data: any = {
        loginParam: decodeURIComponent(this.loginParam)
      }
      console.log('doAuth:data:', data)
      try {
        const res = await httpHelper.post({
          url: 'authorize/zhengchengabc',
          contentType: 'application/x-www-form-urlencoded',
          data: data,
          headers: {
            Authorization: configs.basicAuthorization
          }
        })
        console.log('zhengchengabc', res)
        if (res.code === 0) {
          setABCUser(res.value.extend.banlaiABCUser)
          this.goTo(res, 9, 11)
        } else {
          this.$toasted.show('授权失败')
        }
      } catch (err) {
        this.$toasted.show(err.msg || err.message)
      }
    }
}
